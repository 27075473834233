import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardSubtitle,{staticClass:"white--text secondary"},[_vm._v(" for driver: "),_c('strong',[_vm._v(_vm._s(_vm.driverText))])]),_c(VCardText,[_c(VContainer,[_c('validation-observer',{ref:"observer"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","rows":"4","row-height":"30","clearable":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VDatePicker,{attrs:{"range":"","picker-date":_vm.pickerDate},on:{"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c(VCol,{attrs:{"cols":"6","align-self":"center"}},[_c('validation-provider',{attrs:{"name":"Start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"readonly":"","label":"Start date","error-messages":errors},model:{value:(_vm.formattedDateFrom),callback:function ($$v) {_vm.formattedDateFrom=$$v},expression:"formattedDateFrom"}})]}}])}),_c('validation-provider',{attrs:{"name":"End date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"readonly":"","label":"End date","error-messages":errors},model:{value:(_vm.formattedDateTo),callback:function ($$v) {_vm.formattedDateTo=$$v},expression:"formattedDateTo"}})]}}])})],1)],1)],1)],1)],1),_c(VDivider),_c('edit-actions',{attrs:{"disabled-submit":true},on:{"exit":_vm.exit,"submit":_vm.onSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }