<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-subtitle class="white--text secondary">
          for driver: <strong>{{ driverText }}</strong>
        </v-card-subtitle>

        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model.trim="item.note" auto-grow label="Note" rows="4" row-height="30" clearable>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-date-picker v-model="dateRange" range :picker-date.sync="pickerDate"> </v-date-picker>
                </v-col>
                <v-col cols="6" align-self="center">
                  <validation-provider v-slot="{ errors }" name="Start date" rules="required">
                    <v-text-field
                      v-model="formattedDateFrom"
                      readonly
                      label="Start date"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="End date" rules="required">
                    <v-text-field
                      v-model="formattedDateTo"
                      readonly
                      label="End date"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-divider />
        <edit-actions :disabled-submit="true" @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'
import crudEdit from '../../mixins/crudEdit'
import dateF from '../../services/date.service'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  props: ['visible', 'selectedItem', 'dialogTitle', 'databaseModule', 'driverText'],
  data() {
    return {
      defaultItem: {
        driverId: '',
        driverText: '',
        note: '',
        dateFrom: '',
        dateTo: '',
      },
      dateRange: ['', ''],
      pickerDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    }
  },
  computed: {
    formattedDateFrom() {
      return this.formattedDate(this.item.dateFrom)
    },
    formattedDateTo() {
      return this.formattedDate(this.item.dateTo)
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      handler: 'updateDates',
    },
  },
  methods: {
    updateDates(dates) {
      if (dates[0]) this.item.dateFrom = dates[0]
      else this.item.dateFrom = ''
      if (dates[1]) this.item.dateTo = dates[1]
      else this.item.dateTo = ''
    },
    formattedDate(date) {
      return dateF.formatDate(date)
    },
    submitFormatter(item) {
      let [fromYear, fromMonth, fromDay] = item.dateFrom.split('-')
      item.dateFrom = new Date(Date.UTC(fromYear, fromMonth - 1, fromDay)).toISOString()

      let [toYear, toMonth, toDay] = item.dateTo.split('-')
      item.dateTo = new Date(Date.UTC(toYear, toMonth - 1, toDay)).toISOString()
      item.driverId = this.selectedItem.driverId
    },
    updateFormatter(item) {
      // this.dateRange.splice(0, 1, item.dateFrom.substring(0, 10))
      // this.dateRange.splice(1, 1, item.dateTo.substring(0, 10))
      this.dateRange.splice(0, 2, item.dateFrom.substring(0, 10), item.dateTo.substring(0, 10))
      this.pickerDate = item.dateFrom
    },
    insertFormatter() {
      this.dateRange.splice(0, 2, '', '')
    },
  },
}
</script>
